import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Skus from "../components/Products/Videos";

const Videos = () => (
  <Layout>
    <SEO
      keywords={[
        `Videos`,
        `punk records`,
        `punkrockvideos`,
        `punkrockvideos.com`,
        `punk`,
        `punkrock`,
        `records`,
        `VHS`,
        `sk8`,
        `video`,
        `skateboard`,
        `stickers`,
        `vnyl records`,
      ]}
      title="Videos"
    />
    <h1 className="mt-1 font-semibold text-lg leading-tight truncate">
      Videos
    </h1>
    <p>
      Rare VHS video tapes. The are all out of print. Some have wear on the box
      please look close at the images to see the wear.
    </p>
    <Skus />
  </Layout>
);

export default Videos;
